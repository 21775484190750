
import $ from 'jquery';
import jQueryBridget from 'jquery-bridget'
import Flickity from 'flickity'

// Expose jquery
window.jQuery = window.$ = $; // eslint-disable-line
$( document ).ready(function() {
  $('html').removeClass('no-js').addClass('js');
});

// -----------------------------------------------------------------------------
// Smooth scroll
// -----------------------------------------------------------------------------

// Select all links with hashes
$('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
      &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top - 60
        }, 1000, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            //$target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          };
        });
      }
    }
  });



// -----------------------------------------------------------------------------
// CONTACT PAGE FORM
// -----------------------------------------------------------------------------

$( document ).ready(function() {
  let $form = $( 'form#contact-form' ),
      $fieldSets = $form.find( '.fieldset__group' );

  $fieldSets.each(function() {

    let $this = $( this ),
        $thisInput = $this.find('input, textarea, select');

    if ( $thisInput.val() ) {
      $this.addClass('fieldset--selected');
    }

    $thisInput.on('focus', function(){
      $this.addClass('fieldset--selected');
    });

    $thisInput.on('blur', function(){
      if ( ! $thisInput.val() ) {
        $this.removeClass('fieldset--selected');
      }
    });

  });

});


// -----------------------------------------------------------------------------
// SELECT NAVIGATION
// -----------------------------------------------------------------------------

$('select.nav__select__element').on('change', function(){
  window.location.href = this.value;
})

// -----------------------------------------------------------------------------
// FLICKITY SLIDER
// -----------------------------------------------------------------------------


// Make Flickity a jQuery plugin
Flickity.setJQuery( $ );
jQueryBridget( 'flickity', Flickity, $ );

let flickOpts = {
  cellSelector: '.card',
  cellAlign: 'left',
  contain: false,
  prevNextButtons: false,
  percentPosition: false,
  pageDots: false,

}

$( '.flickInit' ).each(function() {

  let $flickElement = $( this ).find( '.flickity__items' );
  let $flickNavLeft =  $( this ).find( '.flickity-UI__navLeft' );
  let $flickNavRight = $( this ).find( '.flickity-UI__navRight' );

  let $flickSlider,
      visibleCells,
      allCells,
      hasGhostCells,
      realCellCount,
      currentCell,
      needToMove;

  let flick = $flickElement.flickity( flickOpts );
  let flickData = flick.data('flickity');

  let onPhone = window.matchMedia('screen and (max-width: 599px)').matches

  if ( ! onPhone ) {
    $flickNavLeft.addClass('hide')
  }

  $flickElement.on('ready.flickity', function() {
    $flickSlider = $flickElement.find( '.flickity-slider' );
  });


  $flickNavRight.on( 'click', function() {

    $flickSlider = $flickElement.find( '.flickity-slider' );
    visibleCells = Math.ceil( ( $flickSlider.width() - 180 ) / 420 );
    allCells = flickData.cells.length;
    hasGhostCells = $flickSlider.children().last().hasClass('card--ghost');
    realCellCount = hasGhostCells ? allCells - 1 : allCells;
    currentCell = flickData.selectedIndex;
    needToMove = ( realCellCount - currentCell ) > ( visibleCells );

    // console.log('============')
    // console.log('allCells : ', allCells);
    // console.log('hasGhostCells : ', hasGhostCells);
    // console.log('realCellCount : ', realCellCount);
    // console.log('currentCell : ', currentCell);
    // console.log('needToMove : ', needToMove);
    // console.log('============')

    if( needToMove ) {
      $flickElement.flickity( 'next', false );
    }

  });

  $flickNavLeft.on( 'click', function() {
    $flickElement.flickity( 'previous', false );
  });

  $( this ).on( 'select.flickity', function( event, index ) {

    if(index > 0 && !onPhone) {
      $flickNavLeft.removeClass('hide');
    }
    if (index === 0 && ! onPhone) {
      $flickNavLeft.addClass('hide');
    }

  });

}); // $.each

// -----------------------------------------------------------------------------
// PHONE NAV BUTTON
// -----------------------------------------------------------------------------

$( document ).ready(function() {

  let $button = $( 'button.phone-nav-button' );
  let $nav = $( 'nav.phone-nav' );
  let $body = $( 'body' );

  $button.on( 'click', function() {
    $( this ).toggleClass( 'open' );
    $nav.toggleClass( 'open' );
    $body.toggleClass( 'no-scroll' );
  });

});
